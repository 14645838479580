@media (min-width: 768px) {
    /* Tablet and wider screens */
    .machine-form {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
  
    .form-group {
      margin-bottom: 1rem;
    }
  
    h1 {
      grid-column: 1 / -1;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  
  /* Mobile view */
  @media (max-width: 767px) {
    .machine-form {
      display: block;
    }
  
    .form-group {
      margin-bottom: 1rem;
    }
  }  