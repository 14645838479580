* {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --grey: #F1F0F6;
    --dark-grey: #8D8D8D;
    --light: #fff;
    --dark: #000;
    --blue: #1775F1;
    --light-blue: #D0E4FF;
    --dark-blue: #0C5FCD;
    --yellow: #FFCE26;
    --light-yellow: #FFF2C6;
    --orange: #FD7238;
	--light-orange: #FFE0D3;
}

html {
    overflow-x: hidden;
}

body {
    /* background: var(--grey); */
    background-image: url('./bg3.jpg');
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

/* Form Background image */
.bg-image {
    background-image: url('./background.jpg');
    background-size: cover;
    background-position: center;
}

 /* SIDEBAR */
#sidebar {
    position: fixed;
    max-width: 260px;
    width: 100%;
    background: var(--light);
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    transition: all .3s ease;
    z-index: 200;
}

#sidebar.hide {
    max-width: 60px;
}
#sidebar.hide:hover {
    max-width: 260px;
}
#sidebar::-webkit-scrollbar {
    display: none;
}
#sidebar .brand {
    font-size: 24px;
    display: flex;
    align-items: center;
    height: 64px;
    font-weight: 700;
    color: var(--blue);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    background: var(--light);
    transition: all .3s ease;
    padding: 0 6px;
}
#sidebar .icon {
    min-width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}
#sidebar .icon-right {
    margin-left: auto;
    transition: all .3s ease;
}
#sidebar .side-menu {
    margin: 36px 0;
    padding: 0 20px;
    transition: all .3s ease;
}
#sidebar.hide .side-menu {
    padding: 0 6px;
}
#sidebar.hide:hover .side-menu {
    padding: 0 20px;
}
#sidebar .side-menu a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--dark);
    padding: 12px 16px 12px 0;
    transition: all .3s ease;
    border-radius: 10px;
    margin: 4px 0;
    white-space: nowrap;
}
#sidebar .side-menu > li > a:hover {
    background: var(--grey);
}
#sidebar .side-menu > li > a.active .icon-right {
    transform: rotateZ(90deg);
}
#sidebar .side-menu > li > a.active,
#sidebar .side-menu > li > a.active:hover {
    background: var(--blue);
    color: var(--light);
}
#sidebar .divider {
    margin-top: 24px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--dark-grey);
    transition: all .3s ease;
    white-space: nowrap;
}
#sidebar.hide:hover .divider {
    text-align: left;
}
#sidebar.hide .divider {
    text-align: center;
}
#sidebar .ads {
    width: 100%;
    padding: 20px;
}
#sidebar.hide .ads {
    display: none;
}
#sidebar.hide:hover .ads {
    display: block;
}
#sidebar .ads .wrapper {
    background: var(--light);
    padding: 20px;
    border-radius: 10px;
}
#sidebar .btn-upgrade {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    color: var(--light);
    background: var(--blue);
    transition: all .3s ease;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 12px;
}
#sidebar .btn-upgrade:hover {
    background: var(--dark-blue);
}
#sidebar .ads .wrapper p {
    font-size: 12px;
    color: var(--dark-grey);
    text-align: center;
}
#sidebar .ads .wrapper p span {
    font-weight: 700;
}

/* Mobile view */
@media (max-width: 768px) {
    #sidebar.hide:hover {
        max-width: 60px; /* Prevents sidebar from expanding on hover */
    }
    #sidebar.hide:hover .side-menu {
        padding: 0 6px; /* Prevents side-menu from expanding on hover */
    }
    #sidebar.hide:hover .divider {
        text-align: center; /* Keeps divider text centered */
    }
    #sidebar.hide:hover .ads {
        display: none; /* Keeps ads hidden */
    }
}
/* SIDEBAR */


/* CONTENT */
#content {
    position: relative;
    width: calc(100% - 260px);
    left: 260px;
    transition: all .3s ease;
}
#sidebar.hide + #content {
    width: calc(100% - 60px);
    left: 60px;
}
/* NAVBAR */
nav {
    background: var(--light);
    height: 64px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    grid-gap: 28px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
}
nav .toggle-sidebar {
    font-size: 18px;
    cursor: pointer;
}

main .breadcrumbs {
	display: flex;
	grid-gap: 6px;
}
main .breadcrumbs li,
main .breadcrumbs li a {
	font-size: 14px;
}
main .breadcrumbs li a {
	color: var(--blue);
}
main .breadcrumbs li a.active,
main .breadcrumbs li.divider {
	color: var(--dark-grey);
	pointer-events: none;
}

.box-info {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 24px;
	margin-top: 36px;
}
.box-info li {
    padding: 24px;
    background: var(--light);
    border-radius: 20px;
    display: flex;
    align-items: center;
    grid-gap: 24px;
    position: relative; /* Added */
  }
  
  .box-info li .fas {
    width: 80px;
    height: 80px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; /* Added */
    left: 24px; /* Adjust as needed */
    top: 50%; /* Adjust as needed */
    transform: translateY(-50%); /* Added */
  }
  
  .box-info li .text {
    margin-left: 120px; /* Adjust as needed */
  }
  
  .box-info li:nth-child(1) {
    background: var(--light-blue);
  }
  
  .box-info li:nth-child(2) {
    background: var(--light-yellow);
  }
  
  .box-info li:nth-child(3) {
    background: var(--light-orange);
  }
  
  .box-info li .text h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark);
  }
  
  .box-info li .text p {
    color: var(--dark);
  }

.image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px; /* Adjust margin as needed */
        }

.forklift{
            max-width: 100%; /* Ensure the image doesn't exceed its container */
            height: auto; /* Maintain aspect ratio */
            max-height: 300px; /* Set a maximum height if needed */
        }

/* NAVBAR */

@media screen and (max-width: 576px) {
    .box-info {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-top: 36px;
    }

    .box-info li {
        padding: 16px;
        background: var(--light);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-right: 16px; /* Add margin from the right side */
    }

    .box-info li:last-child {
        margin-right: 0; /* Remove margin from the last element */
    }

    .box-info li .fas {
        font-size: 36px;
        margin-bottom: 12px;
    }

    .box-info li:nth-child(1) {
        background: var(--light-blue);
    }

    .box-info li:nth-child(2) {
        background: var(--light-yellow);
    }

    .box-info li:nth-child(3) {
        background: var(--light-orange);
    }

    .box-info li .text h3 {
        font-size: 18px;
        font-weight: 600;
        color: var(--dark);
        margin-bottom: 6px;
    }

    .box-info li .text p {
        font-size: 14px;
        color: var(--dark);
        margin-bottom: 0;
    }
}


@media screen and (max-width: 768px) {
    #content {
        position: relative;
        width: calc(100% - 60px);
        transition: all .3s ease;
    }
    nav .nav-link,
    nav .divider {
        display: none;
    }
    .box-info {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 20px;
        margin-top: 36px;
    }

    .box-info li {
        padding: 20px;
        margin-right: 20px;
    }

    .box-info li:last-child {
        margin-right: 0;
    }

    .box-info li .fas {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .box-info li .text h3 {
        font-size: 20px;
        margin-bottom: 4px;
    }

    .box-info li .text p {
        font-size: 14px;
    }
}



/* UserDashboard CSS */

.users-page {
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 38%;
}

th {
    background-color: #f2f2f2;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

/* Previous and Next Page Button */
.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #0056b3;
}

.search-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.search-input:focus {
    border-color: #007bff;
    outline: none;
}

@media (max-width: 768px) {
    .search-bar-container {
        flex-direction: column;
        align-items: stretch;
    }

    .search-input {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

/* Responsive design */
@media screen and (max-width: 768px) {
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    th, td {
        display: inline-block;
    }

    td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }

    tr {
        display: block;
        border-bottom: 1px solid #ddd;
    }
}
@media screen and (min-width: 769px) {
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    th, td {
        display: inline-block;
        width: 38%;
    }

    td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }

    tr {
        display: block;
        border-bottom: 1px solid #ddd;
    }
}